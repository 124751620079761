// container imports
import ChartDonut from "@/containers/chart-donut";
import RestoreBtn from "@/components/restore-btn";
import SetStatus from "@/containers/set-status";
import noteCard from "@/containers/cards/note";
import CardNotes from "@/containers/card-notes";

// component imports
import Badge from "@/components/badge";
import Tags from "@/components/tags";
import Input from "@/components/input";
import ModalAddContent from "@/components/modal-add-content";
import Avatar from "@/components/avatar";
import Button from "@/components/button";
import Categorized from "@/components/categorized";

import {getPlatformName, getPlatformURL, getSocialProfileIcon} from "@/utils/functions";
import {mapGetters} from "vuex";
import axios from "@/axios";
import NeoAnalyse from "@/components/analyse";

export default {
    name: "neo-social-profile",
    components: {
        "neo-tags": Tags,
        "neo-avatar": Avatar,
        "neo-button": Button,
        "neo-input": Input,
        "neo-set-status": SetStatus,
        "neo-chart-donut": ChartDonut,
        "neo-categorized": Categorized,
        "neo-restore-btn": RestoreBtn,
        "neo-note-card": noteCard,
        "neo-card-notes": CardNotes,
        "neo-badge": Badge,
        NeoAnalyse,
    },
    props: ["id", "content", "setStatus", "component", "moreInfo", "restore", "tab", "showNotes", "hideKeyFacts"],
    data() {
        return {
            controls: {
                status: this.content.status,
            },
            acceptedPlatforms: ["twitter", "flickr", "facebook", "x"],
            tempUsernameHolder: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode"]),
        checkScreenshot() {
            if (this.content.screenshot) {
                if (!this.content.screenshot.available) return false;
                else if (this.content.screenshot.location == "") return false;
                else return true;
            }
            return false;
            // return (this.content.screenshot?.available ?? false) && content.screenshot.location != ''
        },
        flags() {
            let matching_profile = this.$store.getters.getConsolidatedFlags.find((elem) => {
                if (elem.username) return elem.username === this.content.username && elem.platform == this.content.platform;
                else return elem.platform == this.content.platform;
            });
            if (matching_profile) {
                return matching_profile.categories.map((cat) => {
                    if (typeof cat === "object" && cat !== null) return cat;
                    return {tag: cat};
                });
            }
            return [];
        },
        contentPlatform() {
            return this.acceptedPlatforms.includes(this.content.platform);
        },
        updateDoc() {
            return {
                case_id: this.$store.getters.getCaseId,
                username: this.content.username,
                platform: this.content.platform,
                id: this.content._id,
                is_collapse: this.content.is_collapse,
            };
        },
        getNotes() {
            let notes = [];
            if (!this.$store.getters.getReadOnlyMode) {
                notes.push({title: "Notes", desc: "notes" in this.content ? this.content.notes : "Not Available", key: "notes"});
            }

            notes.push({title: "Customer Notes", desc: "customer_notes" in this.content ? this.content.customer_notes : "Not Available", key: "customer_notes"});
            return notes;
        },
    },
    mounted() {},
    methods: {
        getPlatformName,
        getPlatformURL,
        getSocialProfileIcon,

        toggleProvider(provider) {
            this.content[provider] = !this.content[provider];
            this.$emit("dataChanges", {...this.content});
        },
        popIrrelevantData() {
            delete this.content.confidence_score;
            this.$emit("dataChanges", {...this.content, status: null, tab: this.tab});
        },
        onStatChanges(status) {
            this.controls.status = status;
            if (status !== "IRRELEVANT") {
                let screenshotRequired = this.$store.getters.getSocialPlatforms.find((el) => el.key === this.content.platform)?.require_screenshot_in_report ?? false;
                if (screenshotRequired) {
                    if (!this.checkScreenshot) {
                        this.$toast.error("Screenshot required");
                    }
                }
            }
            this.$emit("dataChanges", {...this.content, status});
        },
        onTagChanges(categories) {
            let cats = categories.map((e) => e.tag);
            this.$emit("dataChanges", {...this.content, risk_categories: cats});
        },
        onRagChanges(ragstatus) {
            this.$emit("dataChanges", {...this.content, entity_status: ragstatus});
        },
        startEdit() {
            let item = JSON.parse(JSON.stringify(this.content));
            item.relations = item.relations.filter((relation) => {
                return relation.relationship_type !== "visual";
            });

            this.$store.dispatch("setSelectedEntityData", {
                datatype: "social_profile",
                all_data: item,
                editEntity: true,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");
        },
        cancelSave() {
            this.content.editMode = false;
            this.content.username = this.tempUsernameHolder;
        },
        completeSave() {
            axios.put(`/add-social-profile`, this.updateDoc).then((response) => {});
            this.content.editMode = false;
        },
        onAddContent() {
            this.$store.dispatch("matchingProfile", {
                username: this.content.username,
                platform: this.content.platform,
            });
            this.$modal.show(
                ModalAddContent,
                {
                    text: "This text is passed as a property",
                    heading: "Add Content Analysis",
                    datatype: "missing",
                    card: this.content,
                },
                {
                    height: "auto",
                    width: "40%",
                    styles: {
                        overflow: "inherit",
                    },
                },
                {
                    "before-close": (event) => {},
                    tagChange: (tagChange) => {},
                    ragChange: (ragStatus) => {},
                }
            );
        },
    },
};
